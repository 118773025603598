<template>
  <div>
    <h5 class="blue-text font-weight-bold" v-if="usersData">
      <router-link
        :to="{
          name: 'adminRoute-users-info',
          params: {
            id: $route.params.id,
          },
        }"
      >
        <font-awesome-icon
          class="mr-3"
          :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
        />
      </router-link>
      様への課金履歴 of {{ formatUserName(usersData) }}
    </h5>
    <div class="row mt-5" v-if="billingHistoryTableData">
      <div class="col-12 col-md-12 col-lg-12 col-xl-10">
        <div v-if="billingHistoryTableData.data.length > 0">
          <table class="table shadow-1" striped hover>
            <thead>
              <tr>
                <th scope="col">リクエスト番号</th>
                <th scope="col">注文数</th>
                <th scope="col">ご購入日</th>
                <th scope="col" colspan="2">ご請求額</th>
              </tr>
            </thead>
            <tbody v-if="billingHistoryTableData.data.length === 0">
              <tr v-for="(el, i) in 15" :key="i">
                <td><b-skeleton></b-skeleton></td>
                <td><b-skeleton></b-skeleton></td>
                <td><b-skeleton></b-skeleton></td>
                <td><b-skeleton></b-skeleton></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(el, i) in billingHistoryTableData.data" :key="i">
                <td
                  data-label="リクエスト番号"
                  class="align-middle text-center"
                >
                  {{ el.staging_req_id }}
                </td>
                <td data-label="注文数" class="align-middle text-center">
                  {{ el.staging_request.request_items.length }}
                </td>
                <td data-label="ご購入日" class="align-middle text-center">
                  {{ formatDate(el.updated_at) }}
                </td>
                <td data-label="ご請求額" class="align-middle text-center">
                  {{ formatPrice(el.amount) }}
                </td>
              </tr>
            </tbody>
          </table>
          <pagination
            class="mt-3 mb-0"
            :data="pagination.data"
            :limit="pagination.limit"
            :show-disabled="pagination.showDisabled"
            :size="pagination.size"
            :align="pagination.align"
            @pagination-change-page="getUserBilling"
          />
        </div>
        <div v-else>
          <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
            <img src="/img/icons/ico-info.svg" alt="ico-info" />
            <h3 class="m-0 mt-3 grey-text">表示できる内容がありません。</h3>
          </b-card>
        </div>
      </div>
    </div>

    <div class="row mt-5" v-else>
      <div class="col-12 col-md-12 col-lg-12 col-xl-10">
        <table class="table shadow-1" striped hover>
          <thead>
            <tr>
              <th scope="col">リクエスト番号</th>
              <th scope="col">注文数</th>
              <th scope="col">ご購入日</th>
              <th scope="col">ご請求額</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(el, i) in 15" :key="i">
              <td><b-skeleton></b-skeleton></td>
              <td><b-skeleton></b-skeleton></td>
              <td><b-skeleton></b-skeleton></td>
              <td><b-skeleton></b-skeleton></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// Import Services
import { adm_userService_GetUserBilling } from '../../services/admin/users';
import { adm_userService_GetDetailsByID } from '../../services/admin/users';

// Import Moment JS
import moment from 'moment';

// Import Common
import { formatDate, formatPrice, formatUserName } from '../../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Users | Billing History',
    };
  },
  data() {
    return {
      usersData: null,
      billingHistoryTableData: null,
      pagination: {
        data: null,
        limit: 5,
        showDisabled: false,
        size: 'default',
        align: 'center',
      },
    };
  },

  watch: {
    'pagination.limit'(newVal) {
      this.pagination.limit = parseInt(newVal);
      if (this.pagination.limit < 0) {
        this.pagination.limit = 0;
      }
    },
  },

  mounted() {
    this.getUserData();
    this.getUserBilling();
  },

  methods: {
    formatDate,
    formatPrice,
    formatUserName,
    getUserBilling(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');
      const user_id = _this.$route.params.id;
      adm_userService_GetUserBilling({
        token: accessToken,
        user_id: user_id,
        page: page ? page : 1,
      })
        .then((response) => {
          let data = response.data.result;
          _this.billingHistoryTableData = data;
          _this.pagination.data = data;
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserData() {
      let _this = this;
      let accessToken = localStorage.getItem('_accessToken');
      adm_userService_GetDetailsByID({
        id: _this.$route.params.id,
        token: accessToken,
      })
        .then((response) => {
          _this.usersData = response.data.result;
          console.log('user', _this.usersData);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
/* Init Style */
@media screen and (max-width: 600px) {
  table {
    box-shadow: unset !important;
    border: 0;
    border-radius: 0px !important;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    display: block !important;
    margin-bottom: 15px;
  }
  table td {
    display: block;
    text-align: right !important;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    font-weight: bold;
    content: attr(data-label);
    float: left;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
